import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addConnection,
  deleteConnection,
  getProfileByUserId,
  incrementVisitsNumber,
} from "../../api/apis";
import ProfileWithSocialMedia from "../../components/ProfileWithSocialMedia";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { Box, Button } from "@mui/material";
import {
  checkUserRole,
  errorParser,
  isLoggedIn,
  prepareTranslation,
  BASIC_ROLE,
  ADMIN_ROLE,
} from "../../plugins/helper";
import { useTranslation } from "react-i18next";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { actionButtonStyle, buttonIconStyle } from "./style";
import { useDispatch } from "react-redux";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const TagPage = () => {
  const theme = useTheme();
  const [profile, setProfile] = useState(null);
  const { userId } = useParams();
  const [error, setError] = useState("");
  const { me } = useSelector((store) => store.auth);
  const { ownProfile } = useSelector((store) => store.core);

  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (userId != null) {
      getProfileByUserId(userId)
        .then((res) => {
          setProfile(res.data);
          if (checkUserRole(me?.role, BASIC_ROLE) && ownProfile != null)
            incrementVisitsNumber(res.data.id);
        })
        .catch((err) => {
          setError(errorParser(err));
        });
    }
  }, [userId, me, ownProfile]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");

  const dispatch = useDispatch();

  const confirmDialogEndActions = () => {
    setConfirmDialogOpen(false);
    getProfileByUserId(userId).then((res) => {
      setProfile(res.data);
    });
  };

  const connectionDelete = () => {
    deleteConnection(userId)
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully deleted connection",
            isError: false,
          })
        );
      })
      .finally(() => {
        confirmDialogEndActions();
      });
  };

  const connectionAdd = () => {
    addConnection(userId)
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully added connection",
            isError: false,
          })
        );
      })
      .finally(() => {
        confirmDialogEndActions();
      });
  };

  return error?.length > 0 ? (
    <ErrorIllustrative error={error} containerProps={{ height: "70%" }} />
  ) : profile != null ? (
    <>
      <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmAction={confirmAction}
        message={confirmMessage}
      />
      {!(isLoggedIn() && checkUserRole(me?.role, BASIC_ROLE)) ? (
        <Button
          onClick={() =>
            navigate(
              !isLoggedIn()
                ? `/login?redirectusertag=${userId}`
                : checkUserRole(me?.role, ADMIN_ROLE)
                ? "/adminpanel"
                : "/moderatorpanel"
            )
          }
          sx={actionButtonStyle}
          variant="outlined"
        >
          <AssignmentReturnOutlinedIcon sx={buttonIconStyle} />
          {t(
            prepareTranslation(
              !isLoggedIn()
                ? "Login"
                : checkUserRole(me?.role, ADMIN_ROLE)
                ? "A. panel"
                : "M. panel"
            )
          )}
        </Button>
      ) : ownProfile == null ? (
        <Box
          sx={{ position: "relative", left: 0, top: "20px", height: "35px" }}
        />
      ) : profile?.areConnected ? (
        <Button
          onClick={() => {
            setConfirmAction(() => () => {
              connectionDelete();
            });
            setConfirmMessage("Delete connection");
            setConfirmDialogOpen(true);
          }}
          sx={{
            ...actionButtonStyle,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            "&:hover": {
              borderColor: theme.palette.primary.main,
              background: "#c704040f",
            },
          }}
          variant="outlined"
        >
          <FavoriteBorderOutlinedIcon sx={buttonIconStyle} />
          {t(prepareTranslation("Delete connection"))}
        </Button>
      ) : (
        <Button
          onClick={() => {
            setConfirmAction(() => () => {
              connectionAdd();
            });
            setConfirmMessage("Add to connections");
            setConfirmDialogOpen(true);
          }}
          sx={actionButtonStyle}
          variant="outlined"
        >
          <FavoriteBorderOutlinedIcon sx={buttonIconStyle} />
          {t(prepareTranslation("Add to connections"))}
        </Button>
      )}
      <ProfileWithSocialMedia
        profile={profile}
        containerMarginTop="-50px"
        baner={profile?.bannersUri[0]}
      />
    </>
  ) : null;
};

export default TagPage;
