import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateLanguagePreference } from '../../redux/features/authSlice';

const flags = {
  ba: '🇧🇦',  // Bosnia and Herzegovina
  mk: '🇲🇰',  // North Macedonia
  en: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',  // United Kingdom (for English)
  it: '🇮🇹',  // Italy
  fr: '🇫🇷',  // France (for French)
  es: '🇪🇸',  // Spain (for Spanish)
  sr: '🇷🇸',  // Serbia (for Serbian)
  de: '🇩🇪',  // Germany (for German)
};

const LanguageSwitcherHorizontal = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const languagePreference = useSelector(state => state.auth.languagePreference);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(updateLanguagePreference(lang));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      {Object.entries(flags).map(([lang, flag]) => (
        <Tooltip key={lang} title={lang.toUpperCase()}>
          <IconButton
            onClick={() => changeLanguage(lang)}
            sx={{
              fontSize: '1.5rem',
              opacity: languagePreference === lang ? 1 : 0.5,
              '&:hover': { opacity: 0.8 },
            }}
          >
            {flag}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

export default LanguageSwitcherHorizontal;